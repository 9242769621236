<template>
  <div class="about">
    <Header :category-list="categoryList" :from="name" />
    <div class="container">
      <div class="banner"><img src="@/assets/img/about/about.jpg" /></div>
      <div class="tabs">
        <div class="tabs-box">
          <div class="tabs-item" :class="{'active': activeName == 'introduce' }" @click="checkTabs('introduce')">
            <div class="tab-icon">
              <img v-show="activeName == 'introduce'" src="@/assets/img/about/abouticon_active.png" />
              <img v-show="activeName != 'introduce'" src="@/assets/img/about/abouticon.png" />
            </div>
            企业介绍
          </div>
        </div>
        <div class="tabs-box">
          <div class="tabs-item tabs-history" :class="{'active': activeName == 'history' }"
            @click="checkTabs('history')">
            <div class="tab-icon">
              <img v-show="activeName == 'history'" src="@/assets/img/about/abouticon_active.png" />
              <img v-show="activeName != 'history'" src="@/assets/img/about/abouticon.png" />
            </div>
            品牌大事记
          </div>
        </div>
      </div>
      <Introduce v-show="activeName == 'introduce'"></Introduce>
      <History v-show="activeName == 'history'"></History>
    </div>
    <Footer />
  </div>
</template>

<script>
  // @ is an alias to /src
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import Introduce from '@/views/about/Introduce.vue'
  import History from '@/views/about/History.vue'
  import {
    getFirstAndSecondCategoryUrl
  } from '@/api/common'

  export default {
    name: 'About',
    components: {
      Header,
      Footer,
      Introduce,
      History
    },
    data() {
      return {
        name: 'About',
        categoryList: [],
        activeName: 'introduce'
      }
    },
    mounted() {
      this.getCategory()
    },
    methods: {
      checkTabs(name) {
        this.activeName = name
      },
      getCategory() {
        getFirstAndSecondCategoryUrl().then(res => {
          this.categoryList = res.data.datas
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  // 主题色
  @primary-color: var(--primary-color);

  .active {
    color: @primary-color  !important;
    border-bottom-color: @primary-color  !important;
  }

  .about {
    background: #F7F9FA;

    .banner {
      width: 100%;
    }

    .tabs {
      width: 100%;
      height: 148px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      border-bottom: 1px solid #ccc;

      .tabs-box {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }

      .tabs-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 13px;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        line-height: 28px;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        cursor: pointer;

        .tab-icon {
          width: 36px;
          height: 36px;
          margin-right: 16px;
        }
      }

      .tabs-history {
        margin-left: 275px;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    .tabs-box {
      width: 50% !important;
    }
    .tabs-history {
      margin-left: 0 !important;
    }
    .content-title {
      width: 98%;
      margin: 0 auto;
    }
    .content-img {
      display: none !important;
    }
  }
</style>
