<template>
  <div class="introduce">
    <div class="content">
      <div class="content-box introduce-box flex-b">
        <div class="content-title">
          <h3>上海医疗器械（集团）有限公司手术器械厂</h3>
          <p>
            金钟牌手术器械于2015年4月正式归入鱼跃医疗,并在鱼跃医疗的全力支持下，斥资数千万全力打造了国际先进水准的数字化生产线。秉承“品质铸就品牌”、“为用户着想，做得更好”的理念，金钟牌手术器械将摆脱机制的束缚，激发出无限的进取心与创造性，立志成为一个规模更大、水平更高、产品更丰富的中国手术器械领军品牌。
          </p>
        </div>
        <div class="content-img"><img src="@/assets/img/about/content.jpg" /></div>
        <div class="content-nav">
          <div class="nav-body">
            <div class="content-item">
              <h3 class="primary-color">1928年</h3>
              <p>金钟创立时间</p>
            </div>
            <p class="br"></p>
            <div class="content-item">
              <h3>1000万+</h3>
              <p>年产手术器械</p>
            </div>
            <p class="br"></p>
            <div class="content-item">
              <h3>14000+</h3>
              <p>品种规格</p>
            </div>
            <p class="br"></p>
            <div class="content-item">
              <h3>40+</h3>
              <p>产销国家地区</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="culture">
      <div class="culture-box introduce-box">
        <h2 class="introduce-title">企业文化</h2>
        <p class="introduce-br"></p>
        <p class="introduce-auxiliary">CORPORATE CULTURE</p>
        <div class="culture-list flex-b">
          <div class="box-card culture-box1">
            <div class="culture-img"><img src="@/assets/img/about/introduce1.jpg" /></div>
            <div class="culture-body">
              <h2 class="culture-title">企业使命</h2>
              <p class="culture-auxiliary">CORPORATE MISSION</p>
              <p class="culture-br"></p>
              <p class="culture-tle">致力于人类的健康事业，提供人性化、专业化、规模化的医疗产品和服务，实现企业价值和履行社会责任。</p>
            </div>
          </div>
          <div class="box-card culture-box2">
            <div class="culture-img"><img src="@/assets/img/about/introduce2.jpg" /></div>
            <div class="culture-body">
              <h2 class="culture-title">企业愿景</h2>
              <p class="culture-auxiliary">CORPORATE VISION</p>
              <p class="culture-br"></p>
              <p class="culture-tle">打造一个充分展现医疗技术发展最前沿成果的“金钟”专业品牌，成为一个具有行业引领地位的中国手术器械集成制造企业和用户最有价值的合作伙伴。</p>
            </div>
          </div>
          <div class="box-card culture-box3">
            <div class="culture-img"><img src="@/assets/img/about/introduce3.jpg" /></div>
            <div class="culture-body">
              <h2 class="culture-title">企业理念</h2>
              <p class="culture-auxiliary">CORPORATE PHILOSOPHY</p>
              <p class="culture-br"></p>
              <p class="culture-tle">基于务实的敬业精神，锐于创新的进取精神，勇于争挑的担当精神，善于协同的团队精神。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overview">
      <div class="overview-box introduce-box">
        <h2 class="introduce-title">产品概况</h2>
        <p class="introduce-br"></p>
        <p class="introduce-auxiliary">PRODUCT OVERVIEW</p>
        <div class="overview-list">
          <p class="overview-p">
            金钟牌手术器械现有品种规格14000余种，几乎覆盖所有的临床科目。金钟牌手术器械主要包括：基础器械（如：医用剪、医用钳、医用镊等)、专科器械（如：神经外科、眼科、耳鼻咽喉科、口腔科、胸外科、普通外科、肝胆外科、泌尿外科、肛肠外科、妇产科、小儿科、骨科、显微外科、整形外科等)、成套手术器械包（如：基础手术器械包、专科手术器械包等)、电动工具（如：骨科等手术中应用的各种电钻、电锯等)、微创器械（如：腹腔镜器械、宫腔镜器械、胸腔镜器械等)。几十年来，金钟牌手术器械以其强大的制造规模与上乘的品质水准称雄国内市场，金钟牌手术器械以其繁多的花式品种与良好的售后服务深入各级医院。
          </p>
          <div class="overview-img"><img src="@/assets/img/about/aboutbtm.jpg" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Introduce'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  // 主题色
  @primary-color: var(--primary-color);

  .primary-color {
    color: @primary-color  !important;
  }

  .introduce {
    background: #F7F9FA;

    .content {
      width: 100%;
      background: #FFFFFF;

      .content-box {
        position: relative;
        padding: 60px 0 115px;

        .content-title {
          width: 647px;
          padding-top: 42px;
          text-align: left;

          h3 {
            height: 45px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
          }

          p {
            padding-top: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
          }
        }

        .content-img {
          width: 599px;
          height: 337px;
        }

        .content-nav {
          position: absolute;
          left: 0;
          bottom: -100px;
          width: 100%;
          height: 170px;
          box-shadow: 0px 1px 12px 0px rgba(212, 210, 210, 0.5);
          background: #FFFFFF;
          border-radius: 4px;

          .nav-body {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0 88px;

            .content-item {
              h3 {
                font-size: 32px;
                font-weight: 600;
                color: #333333;
                line-height: 45px;
              }

              p {
                padding-top: 7px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 20px;
              }
            }

            .br {
              width: 1px;
              height: 56px;
              background: #999999;
              border-radius: 4px;
            }
          }
        }
      }

    }

    .culture {
      width: 100%;

      .culture-box {
        padding: 196px 0 48px;

        .culture-list {
          padding-top: 20px;
          align-items: center;

          .box-card {
            width: 30%;
            background: #FFFFFF;
            border-radius: 8px;

            .culture-img {
              width: 100%;
            }

            .culture-body {
              padding: 29px 48px 13px;
              text-align: left;

              .culture-title {
                width: 100%;
                height: 33px;
                font-size: 24px;
                font-weight: 500;
                color: @primary-color;
                line-height: 33px;
              }

              .culture-auxiliary {
                width: 100%;
                height: 20px;
                margin-top: 8px;
                font-size: 14px;
                color: #999999;
                line-height: 20px;
              }

              .culture-br {
                width: 100%;
                height: 2px;
                margin: 14px 0 27px;
                background: #F5F5F5;
              }

              .culture-tle {
                height: 112px;
                font-size: 14px;
                color: #333333;
                line-height: 28px;
              }
            }
          }
        }
      }
    }

    .overview {
      width: 100%;
      background: #FFFFFF;

      .overview-box {
        padding: 69px 0 100px;

        .overview-list {
          padding-top: 19px;

          .overview-p {
            padding: 17px 52px 58px 24px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
          }

          .overview-img {
            width: 100%;
          }
        }
      }
    }

    .introduce-box {
      width: 1420px;
      margin: auto;
      text-align: center;
      line-height: 28px;

      .introduce-title {
        width: 100%;
        height: 36px;
        font-size: 24px;
        font-weight: 500;
        color: @primary-color;
      }

      .introduce-br {
        width: 32px;
        height: 1px;
        margin: 1px auto 3px;
        background: #D8D8D8;
      }

      .introduce-auxiliary {
        width: 100%;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1550px) {
    .introduce {
      .content {
        .content-box {
          padding: 51px 0 97px;

          .content-title {
            width: 547px;
            padding-top: 35px;
          }

          .content-img {
            width: 506px;
            height: 285px;
          }

          .content-nav {
            bottom: -85px;
            height: 144px;

            .nav-body {
              padding: 0 74px;
            }
          }
        }
      }

      .culture {
        .culture-box {
          padding: 166px 0 41px;
        }
      }

      .introduce-box {
        width: 1200px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .introduce {
      .content {
        .content-box {
          padding: 31px 0 77px;

          .content-title {
            width: 98vw;
            padding-top: 25px;
            margin: 0 auto;
          }

          h3 {
            font-size: 20px !important;
          }

          .content-img {
            display: none;
          }

          .content-nav {
            display: none;

            .nav-body {
              padding: 0 74px;
            }
          }
        }
      }

      .culture {
        .culture-box {
          padding: 46px 0 21px;
        }
        .flex-b {
          display: block !important;
        }
        .box-card {
          width: 98% !important;
          margin: 20px auto;
        }
      }

      .introduce-box {
        width: 100%;
      }
    }
  }
</style>
