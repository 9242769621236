<template>
  <div class="history">
    <div class="history-box">
      <h3 class="history-title">上海医疗器械（集团）有限公司手术器械厂</h3>
      <p class="history-auxiliary">坚持“为用户着想，做的更好”的服务宗旨，为我国医疗事业的发展做出新的贡献。</p>
      <div class="history-list">
        <div class="list-box flex-b" v-for="(item, index) in historyList" :key="index">
          <div class="list-left">
            <div class="list-year">{{ item.year }}</div>
            <div :class="index == (historyList.length -1) ? 'list-br2' : 'list-br1'"></div>
          </div>
          <span class="list-br"></span>
          <div class="list-right">
            <h4 v-if="item.title">{{ item.title }}</h4>
            <h5 v-else></h5>
            <div class="flex-b">
              <p>{{ item.content }}</p>
              <div class="list-img"><img v-if="item.imgUrl" :src="item.imgUrl" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'History',
    data() {
      return {
        historyList: [{
            year: '2023',
            title: '继续前行',
            content: '通过组织架构调整、重点产品发展、生产能力建设等方面，逐步扩大和巩固市场占有率，逐步打造企业核心竞争力。金钟依然走在改革创新的发展之路上.....',
            imgUrl: require('@/assets/img/about/about_2023.png')
          },
          {
            year: '2016',
            title: '',
            content: '上手金钟手术器械江苏有限公司成立，同时金钟德国研发中心正式挂牌。金钟“极”系列产品正式上线。采用国产临床研发、德国机加工生产的方式引入先进的德国工艺与技术。让民族手术器械品牌再次扬帆起航。',
            imgUrl: require('@/assets/img/about/about_2016.png')
          },
          {
            year: '2015',
            title: '新起航',
            content: '鱼跃医疗全资收购上海医疗器械（集团）有限公司，上海医疗器械（集团）有限公司手术器械厂企业转制成功，从原有国企脱离，并入鱼跃医疗上市公司平台。',
            imgUrl: require('@/assets/img/about/about_2015.png')
          },
          {
            year: '2010',
            title: '',
            content: '上海医疗器械（集团）有限公司手术器械厂注册“金钟”衍生品牌“JZ CLASSIC”。战略目标调整为技术改造和产品升级，对生产设施作了大幅改造，从普通设备过渡到数控设备，从产品改进项目到实施科研项目，产品总数从4000多种扩大到8000多种，计算机在管理上逐步展开应用。',
            imgUrl: require('@/assets/img/about/about_2010.png')
          },
          {
            year: '2001',
            title: '',
            content: '上海手术器械厂更名上海医疗器械（集团）有限公司手术器械厂。全产线产品统一使用“金钟”牌商标。',
            imgUrl: require('@/assets/img/about/about_2001.png')
          },
          {
            year: '1998',
            title: '',
            content: '上海手术器械六厂并入上海手术器械厂。“金钟”牌与“磁极”牌商标并用。',
            imgUrl: require('@/assets/img/about/about_1998.png')
          },
          {
            year: '1979',
            title: '',
            content: '上海手术器械厂使用“磁极”牌商标。上海手术器械六厂正式注册“金钟”商标。',
            imgUrl: require('@/assets/img/about/about_1979.png')
          },
          {
            year: '1971',
            title: '',
            content: '上海手术器械三厂、上海手术器械四厂和上海医械锻坯厂合并，成立上海手术器械厂。由国家投资140万元，厂址在粤秀路351号，建筑面积10339平方米。',
            imgUrl: require('@/assets/img/about/about_1971.png')
          },
          {
            year: '1931',
            title: '',
            content: '懋昌医疗器械工场（上海手术器械六厂前身）开业，厂址在至浦东东昌路，生产场地50~60平方米，人员7~8人。生产持针钳、止血钳、牛痘刀等。开始使用“414金钟牌”商标。',
            imgUrl: ''
          },
          {
            year: '1928',
            title: '起航',
            content: '奚记作坊（上海手术器械十厂前身之一）开业。生产各种铜质手术器械如妇科器械、子宫镜、肛门镜等。 新美工艺社（上海手术器械十厂前身之一）开业。生产红十字袋、口罩、单架床、疝气带等。',
            imgUrl: ''
          }
        ]
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  // 主题色
  @primary-color: var(--primary-color);

  .history {
    background: #FFFFFF;

    .history-box {
      width: 100%;
      padding-top: 60px;

      .history-title {
        width: 100%;
        height: 33px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
      }

      .history-auxiliary {
        width: 100%;
        height: 20px;
        margin-top: 16px;
        text-align: center;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }

      .history-list {
        padding: 52px 0 92px;

        .list-box {
          width: 887px;
          margin: auto;
          align-items: flex-start;

          .list-left {
            width: 148px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .list-year {
              width: 148px;
              height: 56px;
              border-radius: 4px;
              font-size: 24px;
              color: #FFFFFF;
              background: @primary-color;
              line-height: 56px;
            }

            .list-br1 {
              width: 1px;
              height: 160px;
              background: @primary-color;
            }

            .list-br2 {
              width: 1px;
              height: 116px;
              background: @primary-color;
            }
          }

          .list-br {
            width: 60px;
            height: 1px;
            margin-top: 28px;
            background: @primary-color;
          }

          .list-right {
            width: 648px;
            height: 192px;
            margin-bottom: 24px;
            padding: 22px 24px 24px;
            background: #F7F9FA;

            h4 {
              width: 100%;
              padding-bottom: 10px;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              text-align: left;
              border-bottom: 1px solid #D8D8D8;
              color: @primary-color;

              &::before {
                content: "";
                display: inline-block;
                width: 3px;
                height: 16px;
                margin-right: 12px;
                background-color: @primary-color;
              }
            }

            h5 {
              width: 100%;
              height: 10px;
            }

            p {
              width: 340px;
              margin-top: 14px;
              font-size: 12px;
              color: #333333;
              line-height: 24px;
              text-align: left;
            }

            .list-img {
              width: 192px;
              margin-top: 17px;
            }
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    .history-title {
      font-size: 18px !important;
    }
    .list-box {
      width: 100% !important;
      .list-left {
        width: 20% !important;
      }
      .list-right {
        width: 80% !important;
        .list-img {
          display: none;
        }
      }
    }
  }
</style>
